// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

import 'custom-event-polyfill/polyfill';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'slick-carousel/slick/slick.min';
import 'jquery-match-height/jquery.matchHeight';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import banner from './routes/banner';
import modernizr from './routes/modernizr';
import menu from './routes/menu';
import home from './routes/home';
import templateContact from './routes/templateContact';
import templateLandingpageVasData from './routes/templateLandingpageVasData';
import aboutUs from './routes/about';
import wow from './routes/wow';

/** Populate Router instance with DOM routes */
const routes = new Router({
    // All pages
    wow,
    common,
    banner,
    modernizr,
    menu,
    // Home page
    home,
    // About Us page, note the change from about-us to aboutUs.
    aboutUs,
    templateContact,
    templateLandingpageVasData,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
