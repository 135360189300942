export default {
    init() {
        // JavaScript to be fired on all pages

        if ($(window).width() > 960) {
            $(window).scroll(function() {
                if ($(this).scrollTop() >= 1) {
                    $('.header').addClass('header--fixed');
                } else {
                    $('.header').removeClass('header--fixed');
                }
            });
        }

        jQuery('.service__col').hover(function () {
            if (jQuery(this).find('.block__video').get(0).paused) {
                jQuery(this).find('.block__video').get(0).play();
            }
        },function(){
             jQuery(this).find('.block__video').get(0).pause();
        });

        setTimeout(function() {
            $('.front__content__col').matchHeight();
            $('.block__text').matchHeight();
            $('.team__block__content').matchHeight();
            $('.landingspage__content__inner').matchHeight();
        }, 1000);

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('body').on('click', '.form__material_wrapper .gfield', function() {
            $(this).addClass('form__label-selected');
        });

        $('body').on('focus', '.form__material_wrapper .gfield input', function() {
            $(this).parent().parent().addClass('form__label-selected');
        });

        $('body').on('blur', '.form__material_wrapper .gfield input', function() {
            if ($(this).val()) {
                $(this).parent().parent().addClass('form__label-selected');
            } else {
                $(this).parent().parent().removeClass('form__label-selected');
            }
        });
        $('.form__material_wrapper .gfield input').each(function () {
            var text_value = $(this).val();
            if (text_value !== '') {
                $(this).parent().parent().addClass('form__label-selected');
            }
        });

        $('body').on('click', '.form__material_wrapper .gfield textarea', function() {
            $(this).parent().parent().addClass('form__label-selected');
        });

        $('body').on('focus', '.form__material_wrapper .gfield textarea', function() {
            $(this).parent().parent().addClass('form__label-selected');
        });

        $('body').on('blur', '.form__material_wrapper .gfield textarea', function() {
            if ($(this).val()) {
                $(this).parent().parent().addClass('form__label-selected');
            } else {
                $(this).parent().parent().removeClass('form__label-selected');
            }
        });

        $('.form__material_wrapper .gfield textarea').each(function () {
            var text_value = $(this).val();
            if (text_value !== '') {
                $(this).parent().parent().addClass('form__label-selected');
            }
        });

        var $field = $('.ginput_container_fileupload').find('input[type=file]');

        $field.bind('change',function() {
            if($field.val() != '') {
                $('.file__input').text($field.val().split('\\').pop());
            } else {
                $('.file__input').text('Upload je CV');
            }
        });

        $('.ginput_container_fileupload').each(function () {
            var $label = $(this).parent('li').find('.gfield_label');
            $label.append('<span class="file__input">Upload je CV</span>');
        });

        jQuery(document).on('gform_post_render', function(event, form_id){
            // code to trigger on AJAX form render
            $('input,textarea', $('#gform_' + form_id)).each(function() {
                if ($(this).val() !== '') {
                    $(this).parent().parent().addClass('form__label-selected');
                }
            });
        });

        // JavaScript to be fired on all pages
        $('.landingspage__slider .slider__content').slick({
            dots: false,
            arrows: true,
            swipeToSlide: true,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
            asNavFor: '.slider__nav',
        });

        $('.landingspage__slider .slider__nav').slick({
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 3,
            focusOnSelect: true,
            asNavFor: '.slider__content',
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired

    },
};
