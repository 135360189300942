/* eslint-disable */
import tippy from 'tippy.js';

export default {
    init() {

        $(document).ready(function () {
            // Define a function to set the width and height of the banner slide
            function setBannerSlideDimensions($bannerSlide) {
                let $spans = $bannerSlide.find('span');

                // Initialize variables to keep track of the maximum width and height
                let maxWidth = 0;

                // Loop through each span element to find the maximum width and height
                $spans.each(function () {
                    // Find the dimensions of the current span element
                    let spanWidth = $(this).width();

                    // Add 20px to the span's width and height to the maximum width and height
                    maxWidth = Math.max(maxWidth, spanWidth + 50);
                });

                // Set the inline width and height styles of .vas__banner__slide to match the maximum dimensions
                $bannerSlide.css({
                    'width': maxWidth + 'px',
                });

                if ($(window).width() < 767) {
                    $spans.each(function () {
                        $(this).css({
                            'width': '100%',
                        });
                    });
                }
            }

            // Apply the function to both elements
            setBannerSlideDimensions($('.vas__banner__slide.slide1'));
            setBannerSlideDimensions($('.vas__banner__slide.slide2'));
        });

        $(document).ready(function () {
            // Functie om de animatie voor een slide uit te voeren
            function animateSlide($slide, delay = 0) {
                // Selecteer alle elementen binnen de opgegeven slide
                let $elements = $slide.children();

                // De basisduur voor de timeout
                let timeoutDuration = 4000;

                $elements.each(function (index) {
                    let $currentElement = $(this);

                    // Voeg de 'in' klasse toe aan het huidige element na het berekende aantal seconden met een extra vertraging
                    setTimeout(function () {
                        $currentElement.addClass('in');
                    }, timeoutDuration * index + delay);

                    // Voeg de 'out' klasse toe 4 seconden na de 'in' klasse
                    setTimeout(function () {
                        $currentElement.addClass('out');
                        // Voeg ook de extra vertraging toe voor het toevoegen van de .out__hide klasse
                        setTimeout(function () {
                            $currentElement.addClass('out__hide');
                            $currentElement.removeClass('in out');
                            setTimeout(function () {
                                $currentElement.removeClass('out__hide');
                                // Voor het laatste element, start de animatie opnieuw zonder te wachten tot 'out__hide' wordt verwijderd
                                if (index === $elements.length - 1) {
                                    animateSlide($slide, delay);
                                }
                            }, 1000);
                        }, 1000);
                    }, timeoutDuration * index + 4000 + delay);

                    // Direct na het toevoegen van de 'out' klasse aan het laatste element, start de animatie voor het eerste element
                    if (index === $elements.length - 1) {
                        setTimeout(function () {
                            $slide.children().first().addClass('in');
                        }, timeoutDuration * index + 4000 + delay);
                    }
                });
            }

            // Roep de functie aan om de animatie voor slide1 uit te voeren met een vertraging van 0 ms
            animateSlide($('.vas__banner__slide.slide1'), 0);

            // Roep de functie aan om de animatie voor slide2 uit te voeren met een vertraging van 500 ms
            animateSlide($('.vas__banner__slide.slide2'), 500);
        });


        // JavaScript to be fired on all pages
        $('.vas__service__slider').slick({
            dots: false,
            infinite: false,
            slidesToShow: 4,
            arrows: false,
            swipeToSlide: true,
            autoplay: false,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1699,
                    settings: {
                        slidesToShow: 4.25,
                    }
                },
                {
                    breakpoint: 1399,
                    settings: {
                        slidesToShow: 3.25,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2.25,
                    }
                },
                {
                    breakpoint: 649,
                    settings: {
                        slidesToShow: 1.25,
                    }
                }
            ]
        });

        $('.vas__partners__slider').slick({
            dots: false,
            infinite: false,
            slidesToShow: 7,
            arrows: false,
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1299,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 1099,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 399,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        });

        $('.vasmethod__steps').slick({
            dots: false,
            infinite: false,
            slidesToShow: 4,
            arrows: false,
            swipeToSlide: true,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 1536,
                    settings: {
                        slidesToShow: 3.25,
                    }
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2.25,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.25,
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1.1,
                    }
                }
            ]
        });

        let questionButtons = document.querySelectorAll('.vasfaq__questions__question__button');

        if (questionButtons.length > 0) {
            questionButtons.forEach(questionButton => {
                questionButton.addEventListener('click', function () {
                    let contentHeight = this.nextElementSibling.firstElementChild.offsetHeight;
                    let buttonIcon = this.lastElementChild;

                    if (this.nextElementSibling.style.maxHeight && buttonIcon.style.transform) {
                        this.nextElementSibling.style.maxHeight = null;
                        buttonIcon.style.transform = null;
                    } else {
                        this.nextElementSibling.style.maxHeight = contentHeight + 'px';
                        buttonIcon.style.transform = 'rotate(180deg)';
                    }
                });
            });
        }

        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });

        tippy('[data-tippy-content]');
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

